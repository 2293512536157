<template>
   <QuillEditor theme="snow" toolbar="full" v-model:content="content" ref="refInstance" />
   <!--<button @click="click">getHTML</button>-->
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
   name: "QuillVueEditor",
   components: { QuillEditor },
   data () {
      return {
         content: null,
         htmlContent: null
      }
   },
   methods: {
      setContent () {

      },
      click: function () {
         this.htmlContent = this.$refs.refInstance.getHTML();
      }
   }
}
</script>

<style scoped>

</style>
